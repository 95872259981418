import React from 'react';
import { withLayout } from '../components/Layout';
import {
    Constrain,
    pullbackMarginsSmall,
    pullbackMarginToNormalConstrainFromNarrow,
    Link,
    HeadingLevel3
} from '../components/ui';
import { PageHeader } from '../components/PageHeader/PageHeader.component';
import oldStreetImage from '../images/old-street.svg';
import oldStreetOutline from '../images/old-street-outline.svg';
import { GoogleMap } from '../components/GoogleMap/GoogleMap.component';
import styled from '../styled-components';
import { media } from '../utilities';
import { Initial } from '../components/ui/Initial/Initial.component';
import { FixedMasthead } from '../components/Masthead/FixedMasthead.component';
import Helmet from 'react-helmet';
import CallToContact from '../components/CallToContact/CallToContact.component';
import { BannerAdvert } from '../components/BannerAdvert/BannerAdvert.component';

const Pullout = styled.div`
    ${pullbackMarginsSmall};

    ${media.m`
        margin: 0;
    `}

    ${media.l`
        ${pullbackMarginToNormalConstrainFromNarrow};
    `}
`;

const Heading = styled(HeadingLevel3)`
    line-height: 0.8;
    margin-bottom: ${({ theme }) => theme.spacing.l};
    ${media.xl`
        line-height: 1;
    `}
`;

const ContactOptions = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing.l};
    font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};

    p {
        margin-bottom: ${({ theme }) => theme.spacing.l};
    }

    ${media.l`
        margin-bottom: ${({ theme }) => theme.spacing.xl};
        display: flex;
        justify-content:space-between;

        & > div {
            flex: 1;
        }

        p:last-child {
            margin: 0;
        }
    `}

    ${media.xl`
        font-size: ${({ theme }) => theme.fontNew.primary.size.level4.size};
    `}
`;

const SecondarySubHeading = styled.h4`
    font-family: ${({ theme }) => theme.fontNew.secondary.type};
    font-weight: normal;
    font-size: ${({ theme }) => theme.fontNew.secondary.size.level5.size};
    line-height: ${({ theme }) =>
        theme.fontNew.secondary.size.level5.lineHeight};

    margin-bottom: 0;

    ${media.xl`
        font-size: ${({ theme }) => theme.fontNew.secondary.size.level4.size};
        line-height: ${({ theme }) =>
            theme.fontNew.secondary.size.level4.lineHeight};


    `}
`;

const Intro = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing.l};
    font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};

    ${media.l`
        margin-bottom: ${({ theme }) => theme.spacing.xl};
    `}

    ${media.xl`
        font-size: ${({ theme }) => theme.fontNew.primary.size.level4.size};
    `}
`;

const GetInTouchPage = () => {
    return (
        <>
            <Helmet>
                <title>Let's talk about your digital product idea</title>
                <meta
                    name="description"
                    content="Get in touch to partner with us and build a product that makes waves. Based by Old Street, London."
                />
            </Helmet>
            <FixedMasthead />

            <BannerAdvert />

            <PageHeader
                highlightColor="pink"
                title="Making waves in the heart of London's Silicon Roundabout"
                lineTitle="Get in touch"
                imageUrl={oldStreetImage}
                imageAlt="Illustration of Old Street Round-a-bout with We Make Waves logo on the billboard"
                imageOutlineUrl={oldStreetOutline}
            />

            <Constrain type="narrow">
                <Intro>
                    <Heading as="h3">
                        <Initial color="pink">Hello</Initial> let's make waves.
                    </Heading>

                    <p>Get in touch and let's talk through your ideas.</p>
                </Intro>
                <ContactOptions>
                    <div>
                        <SecondarySubHeading>
                            Send us an email
                        </SecondarySubHeading>

                        <p>
                            <Link
                                to="mailto:together@wemakewaves.digital"
                                highlightColor="pink"
                            >
                                together@wemakewaves.digital
                            </Link>
                        </p>

                        <SecondarySubHeading>
                            Give us a call
                        </SecondarySubHeading>
                        <p>
                            <Link to="tel:02037443554" highlightColor="pink">
                                020 3744 3554
                            </Link>
                        </p>
                    </div>
                    <div>
                        <SecondarySubHeading>Find us here</SecondarySubHeading>
                        <p>
                            We Make Waves
                            <br /> We Work, 41 Corsham Street
                            <br />
                            London
                            <br />
                            N1 6DR
                        </p>
                    </div>
                </ContactOptions>

                <Pullout>
                    <GoogleMap />
                </Pullout>
            </Constrain>

            <CallToContact />
        </>
    );
};

export default withLayout(GetInTouchPage);
