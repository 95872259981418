import styled from '../../../styled-components';
import { media } from '../../../utilities';

// Taking naming inspiration from here: https://en.wikipedia.org/wiki/Initial

export const Initial = styled.span<{ color: 'electricBlue' | 'pink' }>`
    display: inline-block;
    transform: rotate(-7deg);
    font-family: ${({ theme }) => theme.fontNew.secondary.type};
    font-size: ${({ theme }) => theme.fontNew.secondary.size.level3.size};
    line-height: ${({ theme }) => theme.fontNew.primary.size.level2.lineHeight};
    font-weight: normal;
    color: ${({ color, theme }) => theme.colors[color].color};
    margin-right: 0.5rem;

    ${media.xl`
        font-size: ${({ theme }) => theme.fontNew.secondary.size.level2.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level1.lineHeight};
    `}
`;
