import React from 'react';
import loadGoogleMapsApi from 'load-google-maps-api';
import { mapStyles } from './mapStyles';
import styled from '../../styled-components';
import { media } from '../../utilities';

const MAP_OPTIONS = {
    center: {
        lat: 51.5273017,
        lng: -0.0869233
    },
    styles: mapStyles,
    zoom: 15,
    clickableIcons: true,
    disableDoubleClickZoom: false,
    draggable: true,
    keyboardShortcuts: true,
    scrollwheel: false,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false
};

const MapContainer = styled.div`
    height: 23.3rem;
    margin-bottom: ${({ theme }) => theme.spacing.l};

    ${media.m`
        height: 36.6rem;
        margin-bottom: ${({ theme }) => theme.spacing.xl};
    `}

    ${media.l`
        height: 59.4rem;
        margin-bottom: ${({ theme }) => theme.spacing.xxl};
    `}
`;

export class GoogleMap extends React.Component<{}, {}> {
    mapRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        loadGoogleMapsApi({
            key: 'AIzaSyD3v0YhaxSKaJQ7QfcYmDXKyjuUBhHGprI'
        })
            .then(googleMaps => {
                const map = new googleMaps.Map(
                    this.mapRef.current,
                    MAP_OPTIONS
                );

                const markerOptions: any = {
                    map,
                    position: {
                        lat: 51.5273017,
                        lng: -0.0869233
                    },
                    icon: {
                        path:
                            'M11 2c-3.9 0-7 3.1-7 7 0 5.3 7 13 7 13 0 0 7-7.7 7-13 0-3.9-3.1-7-7-7Zm0 9.5c-1.4 0-2.5-1.1-2.5-2.5 0-1.4 1.1-2.5 2.5-2.5 1.4 0 2.5 1.1 2.5 2.5 0 1.4-1.1 2.5-2.5 2.5Z',
                        scale: 2,
                        anchor: new googleMaps.Point(11, 22),
                        fillOpacity: 1,
                        fillColor: '#ff63c1',
                        strokeOpacity: 0
                    }
                };

                // @ts-ignore
                const market = new googleMaps.Marker(markerOptions);
            })
            .catch(e => console.log(e.message));
    }

    render() {
        return <MapContainer ref={this.mapRef} />;
    }
}
